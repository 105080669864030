import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { Organization } from '@/models/Organization';
import ErrorHandler from '@/support/ErrorHandler';
import { Setting, SettingKey } from '@/models/Setting';
import { cloneDeep } from 'lodash';
import { formatDate } from '@/support/String';
import DashboardLLD from '@/views/Planning/Dashboard/DashboardLLD.vue';

@Component<Dashboard>({
  components: {
    DashboardLLD,
  },
})
export default class Dashboard extends Vue {
  public $pageTitle = 'Dashboard';

  protected isLoading = true;

  protected organization: Organization | null = null;

  protected settings: Setting[] = [];

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected async initialize() {
    if (! this.$store.state.isServiceOrganization) {
      this.organization = cloneDeep(this.$store.state.Auth.organization);
      await this.getOrganizationSettings();
    }
  }

  protected async getOrganizationSettings() {
    await new Setting()
      .all()
      .then((settings: Setting[]) => {
        const visiblePlanningEndDate = settings.find((setting: Setting) => setting.key === SettingKey.PLANNING_VISIBLE_END_DATE_FOR_TCMG);

        if (visiblePlanningEndDate && visiblePlanningEndDate.value) {
          (this.organization as any).planningEndDate = formatDate(visiblePlanningEndDate.value as string);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Dashboard' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
