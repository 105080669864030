var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$store.state.isServiceOrganization
      ? _c("div", [_c("DashboardLLD")], 1)
      : _vm._e(),
    !_vm.$store.state.isServiceOrganization
      ? _c(
          "div",
          [
            _c(
              "v-layout",
              { attrs: { wrap: "" } },
              [
                _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
                  _c("h2", { staticClass: "elementSubTitle" }, [
                    _vm._v("Dashboard")
                  ])
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced" },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? _c(
                      "v-layout",
                      { staticClass: "item__list", attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "list__header", attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  wrap: "",
                                  "align-center": "",
                                  "justify-space-between": ""
                                }
                              },
                              [
                                _c("v-flex", { attrs: { xs3: "" } }, [
                                  _c("span", [_vm._v("Expertise Bureau")])
                                ]),
                                _c("v-flex", { attrs: { xs9: "" } }, [
                                  _c("span", [_vm._v("Vrijgegeven t/m")])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        [
                          _c(
                            "v-flex",
                            { staticClass: "list__item", attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    "align-center": "",
                                    "justify-space-between": ""
                                  }
                                },
                                [
                                  _c("v-flex", { attrs: { xs3: "" } }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.organization.name) + " "
                                    )
                                  ]),
                                  _c("v-flex", { attrs: { xs9: "" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.organization.planningEndDate
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }